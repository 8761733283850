/* globals s, nx */

class PriceLoader {
    constructor(selector, postcodeprompt) {
        PriceLoader.initializeElements();
    }

    static initializeElements (selector) {
        selector = selector || '';
        var price_items = document.querySelectorAll(selector + ' .item-price.unloaded');
        var price_struct = [ ];
        var price_seen = { };

        for( let i = 0; i < price_items.length; i ++ ) {
            const d = price_items[i].getAttribute('data-product');
            let pd;
            try{
                pd = JSON.parse( d );
            }finally{
                if( pd ) {
                    /* do not request for the same item more than once. */
                    if( ! price_seen.hasOwnProperty( pd.prodcode ) ) {
                        price_seen[pd.prodcode] = true;
                        price_struct.push( pd );
                    }
                }
            }
        }
        if(price_struct.length > 0){
            PriceLoader.request(price_items, price_struct, selector);
        }
    }

    static request(price_items, price_struct, selector) {
        fetch("https://" + window.location.host + "/_price", {
            credentials: 'same-origin',
            method: "POST",
            body: JSON.stringify(price_struct),
            headers: new Headers({
                'Content-Type': 'application/json'
            })
        })
        .then(response => response.json())
        .then(data => {
            try {
                PriceLoader.apply_results(
                    price_items,
                    data
                );
            } finally {
                PriceLoader.tidy_up(selector);
            }
        });
    }

    static apply_results( items, results ) {
        let rh = { };
        for(let i = 0 ; i < results.length; i++ ) {
            if( results[i].prodcode )
                rh[results[i].prodcode] = results[i];
        }

        for(let i = 0 ; i < items.length ; i ++ ) {
            const item = items[i];
            const d = item.getAttribute('data-product');
            const no_sup = item.classList.contains('no_sup');
            const pd = JSON.parse( d );
            if(rh[pd.prodcode]) {
                item.innerHTML = PriceLoader.format_price(
                    rh[pd.prodcode],
                    no_sup
                );
                item.classList.remove("unloaded");
                item.classList.add("loaded");
            }
        }
    }

    static format_price( price, no_sup ) {
        if( price.poa ) {
            if (nx.ute.readDatalayer(['global', 'streams', 'itemcard'])) {
                return '<a class="itemcard__price__chat" href="javascript:nx.chat.startChat(\'Price on application\')" title="Price On Application\nPlease chat">Chat</a>';
            } else {
                return "<a href='" + nxDatalayer.global.faq + "#call' target='_blank'><acronym title='Price On Application\nPlease call "+nxDatalayer.global.customer_support_phone+"'>Call</acronym></a>";
            }
       } else {
            var gst = 0;
            if(nxDatalayer.global.show_gst)
                gst = parseInt(price.linetax, 10);

            /* gst will only be non-zero for inc_gst customers */
            var total_price =
                (parseInt(price.lineprice, 10) + gst)
                / 1000;
            var price_string = "" + total_price.toFixed(2);
            if(no_sup) { return "$" + price_string;}
            const parts = price_string.split('.',2);
            return `$${parts[0]}<sup>.${parts[1]}</sup>`;
        }
    }

    static tidy_up(selector) {
        let unresolved_codes = [];
        const items = document.querySelectorAll(selector + ' .item-price.unloaded');
        if(items.length === 0) return false; //nothing to tidy
        for(let i = 0; i < items.length; i++) {
            unresolved_codes.push(items[i].getAttribute('data-product'));

            // Build click for price content
            const d = items[i].getAttribute('data-product');
            const pd = JSON.parse( d );
            const cp_onclick = "nx.prices.getGwPrice('html', this,'" + pd.prodcode + "')";
            const cp_div = document.createElement('div');
            cp_div.className = 'click_price';
            cp_div.setAttribute('onclick', cp_onclick);
            const label = nx.ute.readDatalayer(['global', 'streams', 'itemcard'])
                ? 'Click to load pricing'
                : 'Click for price';
            cp_div.innerHTML = 'Click for price';
            // Find results_price div and it's parent
            const rp = items[i].closest('.results_price');
            if (!rp) continue;
            const rpp = rp.parentNode;

            // Remove the results_price parent div as the click for price js replaces it
            rp.remove();

            // Insert the click for price content within the parent div
            rpp.insertBefore(cp_div, null);
        }
        if(window.s && unresolved_codes.length > 0) {
            var index = Math.floor(unresolved_codes.length * Math.random());
            var prop = 'prop17';
            var evt = 'event76'
            var payload = 'Click for price/' + unresolved_codes[index] + '(' + unresolved_codes.length + ')';
            s.linkTrackVars = prop + ',events';
            s.linkTrackEvents = evt;
            s.events = evt + '=' + unresolved_codes.length;
            s[prop]= payload;
            s.tl(true,'o','Pricing error');
        }
        return false;
    }

    // the inners button on item cards
    // this uses ajax to get the price for a particuar number of a specific product
    static setPlpPrice(button) {
        const itemCard = button.closest('.itemcard');
        let priceSpan = itemCard.querySelector('.item-price');
        if (!priceSpan) { // we're retail cards
            priceSpan = itemCard.querySelector('.itemcard__price');
        }
        const prod = JSON.parse(itemCard.getAttribute('data-product'));
        const buttonContainer = button.parentElement;
        const quantity = button.getAttribute('data-quantity');
        const actButton = itemCard.querySelector('.wc-button .wc-button--primary');

        // unset the classes on the other buttons
        buttonContainer.querySelectorAll('button').forEach(btn => {
            btn.classList.remove('selectable_button--selected');
        });
        // set the class on this button
        button.classList.add('selectable_button--selected');

        if (quantity) {
            // update the image
            this.toggleImageOverlay(quantity, itemCard);
            // they've clicked the pack button
            // check if it's cached
            if(priceSpan.packPrice) {
                priceSpan.innerHTML = priceSpan.packPrice;
            } else {
                // set spinners disable ATC
                if (actButton) {
                    actButton.disabled = true;
                }
                priceSpan.classList.add('mini-cart__updating');
                // fetch the price for the quantity
                fetch('/_priceinner',{
                    headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                    }, method:'POST',body:JSON.stringify({item:prod,quantity})})
                    .then(response => response.json())
                    .then(data => {
                        const formattedPrice = PriceLoader.format_price(data, true);
                        // record the price for a single item
                        priceSpan.eachPrice = priceSpan.innerHTML;
                        // record the price for a pack
                        priceSpan.packPrice = formattedPrice;
                        // display the price for the pack
                        priceSpan.innerHTML = formattedPrice;
                    }).catch(error => {
                        // do nothing
                    }).finally(() => {
                        // remove spinners enable ATC;
                        if (actButton) {
                            actButton.disabled = false;
                        }
                        priceSpan.classList.remove('mini-cart__updating');
                    }
                );
            }
        } else {
            // they've clicked the each button
            // if the each price isn't cached yet, do nothing
            if (!priceSpan.eachPrice) return;
            // reset the displayed price
            // if it exists, we call the watchpricebreaks function to restore the original price
            const qtyField = itemCard.querySelector('.qty_text');
            if (qtyField.updateFunction) {
                qtyField.updateFunction();
            } else {
                // use the one we recorded
                priceSpan.innerHTML = priceSpan.eachPrice;
            }
            // update the image
            this.toggleImageOverlay('', itemCard);
        }
    }

    static toggleImageOverlay(quantity, itemCard) {
        if (!quantity) {
            itemCard?.querySelector('.itemcard__image_overlay')?.remove();
        } else {
            const overlay = document.createElement('div');
            overlay.className = 'itemcard__image_overlay';
            overlay.innerHTML = `&times; ${quantity}`;
            itemCard?.querySelector('.itemcard__image')?.appendChild(overlay);
        }
    }


}

export {PriceLoader};
