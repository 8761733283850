/* globals nx, s, $j */
import { replaceSerialisedValue } from '../ute/utils';
const sessionError = 'Error: [object Promise]';
class AddToCartForm {
    constructor(selector, postcodeprompt) {
        // add_cart_mode is simple or eaches
        this.add_cart_mode = "simple";
        this.add_cart_name = "Add";
        // add cart eaches is 1 or more than 1
        this.add_cart_eaches_qty = 1;

        this.form_selector = 'form.add-to-cart';
        this.button_selector = `[type=submit][value^=Add]:not([name=create_new_list]),
            button.wc-button[value^=Add]:not([name=create_new_list])`;
        this.form_action = '/main-ajax-addtocart';
        this.waiting_prompt = '<div class="container" style="width: auto; position: relative;">'+
            '<div id="adt_container" class="grid_9 alpha omega">'+
            '<p style="text-align: center;"><img src="/media/images/new/ajax-loader.gif" /></p>'+
            '<p style="text-align: center; font-size: large;">please wait...</p>'+
            '</div></div>';
        this.lb_waiting_prompt = '<div id="dialog-content-overlay"><div>'+
            '<p><img src="/media/images/new/ajax-loader.gif" /></p>'+
            '<p>please wait...</p>'+
            '</div></div>';
        this.close = "<p>" + Dialogs.Lang.close + "</p>";

        if (selector) this.form_selector = selector;
        this.postcodeprompt = postcodeprompt;
        if (typeof nxDatalayer === 'undefined' ||
            typeof nxDatalayer.global.profiles === 'undefined' ||
            !nxDatalayer.global.profiles.AjaxAdt) {
        }
        const forms = $j(this.form_selector);
        for(let i = 0; i < forms.length; i++) {
            let atcObj = this;
            const form = forms[i];
            $j(form).find(this.button_selector).each(function() {
                this.addEventListener('click', atcObj.on_click.bind(atcObj));
            });
            // prevent repeated event handler bindings
            form.classList.remove('add-to-cart');
            form.classList.remove('sub-prompt');
            form.classList.remove('bts-prompt');
        }

    }
    wrap_error(message) {
        return nx.ute.wrapK2Message(`<strong>Error - </strong>${message}`, 'error indented min-width'); 
    }
    on_click(ev) {
        // this method either adds to cart or displays the post code lightbox
        // if post code prompt flag is set
        if (this.postcodeprompt && nx.addToCart.DisplayPcPrompt(ev)) {
            return true;
        }
        const button = ev.currentTarget;

        // inners and eaches from pdp
        var data_qty = button.getAttribute('data-qty');
        this.add_cart_name = button.value;

        if (data_qty && typeof data_qty !== 'undefined' && data_qty !== false) {
            this.add_cart_mode = 'eaches';
            this.add_cart_eaches_qty = data_qty;
        } else {
            this.add_cart_mode = 'simple';
        }

        if(button.classList.contains('call-to-action_disabled') || button.classList.contains('call-to-action_discouraged') ) {
            return true;
        }
        ev.stopPropagation();
        ev.preventDefault();
        button.blur();
        this.showDialog(button.form);
        return true;
    }
    get_payload(form, itemcard) {
        //itemcard may be null if from pdp or similar
        const qty_field = form.querySelector("input[name=quantity], input[name=k2c_additeminline_quantity]");
        let prefix = '';
        if (qty_field.name === 'k2c_additeminline_quantity') {
            prefix = 'k2c_additeminline_';
        }
        // pdp eaches
        if(this.add_cart_mode === 'eaches') {
            qty_field.setAttribute("value", this.add_cart_eaches_qty);
            qty_field.value = this.add_cart_eaches_qty;
        }
        let payload = $j(form).serialize();

        // plp inners
        // different from the pdp inners.
        // we multiply the quantity by the inners quantity
        // so they're buying e.g. 4 times pack 10
        const innersButton = itemcard?.querySelector('.itemcard_inner.selectable_button--selected');
        if (innersButton) {
            const newQty = parseInt(qty_field.value, 10) * parseInt(innersButton.getAttribute('data-quantity'), 10);
            payload = replaceSerialisedValue(payload, 'quantity', newQty);
            // override value for eVar94
            this.add_cart_name = 'itemcard inner ' + innersButton.innerText;
        }

        // this sets eVar94
        payload = payload + '&' + prefix + 'add_to_cart_label=' + this.add_cart_name;

        payload += '&' + prefix + 'add_to_cart_location=' + encodeURIComponent(document.location.pathname);

        if(form.querySelector('#pd_submit')){ 
            //blowup
            return payload + '&pd_submit=Add';
        }
        let submitName = 'add_items';
        
        if(form.querySelector(`button[name=k2c_additeminline_submit_button],
                                button[name=k2c_additeminline_submit],
                                input[name=k2c_additeminline_submit],
                                button[name=k2c_additeminline_submit_button_itemcard]`)) {
            submitName = 'k2c_additeminline_submit';
        }
        return payload + '&' + submitName + '=Add';
    }
    add_request_time(form) {
        var fld = form.querySelector("input[name=ajax_request_time]");
        if(!fld) {
            fld = document.createElement("INPUT");
            fld.setAttribute("type", "text");
            fld.setAttribute("name", "ajax_request_time");
            fld.setAttribute("hidden", "hidden");
            form.appendChild(fld);
        }
        fld.setAttribute("value", new Date().getTime());
    }

    showDialog(oform) {
        if (!oform) return;
        // clone form because it may be in another dialog
        // and IE11 will delete it if it is
        var form = oform.cloneNode(true);
        Dialogs.load();
        Dialogs.close();
        var obj = this;
        nx.dlg_add_to_cart = new Dialog({
            padding: 0,
            margin: 0,
            innerScrollOnlyY: true,
            afterOpen: function(d) {
                // now hit the server
                obj.add_request_time(form);

                let payload = obj.get_payload(form, oform.closest('.itemcard'));
                fetch(obj.form_action, {
                    credentials: 'same-origin',
                    body: payload,
                    headers: {
                        'X-Post-ID': nxDatalayer.global.post_id,
                        'X-Allow-Wide': nxDatalayer.event.wide.enabled,
                        'content-type': 'application/x-www-form-urlencoded'
                    },
                    method: "POST"
                })
                .then(response => {
                    if (!response.ok) {
                        throw Error(response.text());
                    }
                    return response.text();
                })
                .then(content =>{
                    Dialogs.elm('close').innerHTML = obj.close;
                    d.opt.content = content;
                    d.setContent();
                    new AddToCartLightBox(d); // eslint-disable-line no-use-before-define
                    new BTSPromptLightBox(d); // eslint-disable-line no-use-before-define
                    /* Restyle large quantity fields */
                    nx.products.add_quantity_controls(); // eslint-disable-line no-undef
                    if( etdp ) {
                        etdp.getAllTargets('#dialog-content');
                        etdp.updateAllTargets();
                    }
                    if(nx.prices.PriceLoader){
                        nx.prices.PriceLoader.initializeElements('#adt_container');
                    }
                })
                .catch(err => {
                    const msg = err == sessionError
                        ? 'There was a problem adding to cart. Please refresh the page and try again.'
                        : err;
                    d.opt.content = obj.wrap_error(msg);
                    d.setContent();
                })
                .finally(() => form.removeAttribute("action"));
            },
            afterClose: function() {
                var closedBy = nx.dlg_add_to_cart.closedBy || 'closed';
                Dialogs.elm('close').innerHTML = Dialogs.Lang.close;
                Dialogs.elm('top').classList.remove("desk-ver");
                nx.dlg_add_to_cart = null;
                if(Math.random() < 0.01) {
                    s.linkTrackVars='None';
                    s.linkTrackEvents='None';
                    s.tl(this,'o','Ajax Cart: ' + closedBy);
                }

            },
            content: obj.waiting_prompt
        });
        nx.dlg_add_to_cart.open();
    }

}

class AddToCartLightBox extends AddToCartForm {
    constructor( dlg) {
        super('#dialog-content #adt_container form.add-to-cart, #dialog-content #adt_container form.sub-prompt');
        this.dlg = dlg;
        // this.form_selector = '#dialog-content #adt_container form.add-to-cart, #dialog-content #adt_container form.sub-prompt';
    }    
    showDialog(oform) {
        // I have to keep the current lightbox open.
        var form = oform.cloneNode(true);
        var obj = this;
        Dialogs.elm('content').firstChild.innerHTML = obj.lb_waiting_prompt;
        obj.add_request_time(form);
        let payload = obj.get_payload(form, oform.closest('.itemcard'));
        fetch(obj.form_action, {
            credentials: 'same-origin',
            body: payload,
            headers: {
                'X-Post-ID': nxDatalayer.global.post_id,
                'X-Allow-Wide': nxDatalayer.event.wide.enabled,
                'content-type': 'application/x-www-form-urlencoded'
            },
            method: "POST"
        })
        .then(response => {
            if (!response.ok) {
                throw Error(response.text());
            }
            return response.text();
        })
        .then(responseText => {
            obj.dlg.opt.content = responseText;
            obj.dlg.setContent();
            new AddToCartLightBox(obj.dlg);
            /* Restyle large quantity fields */
            nx.products.add_quantity_controls(); // eslint-disable-line no-undef
            if( etdp ) {
                etdp.getAllTargets('#dialog-content');
                etdp.updateAllTargets();
            }
            if(nx.prices.PriceLoader){
                nx.prices.PriceLoader.initializeElements('#adt_container');
            }
        })
        .catch(err => {
            const msg = err == sessionError
                ? 'There was a problem adding to cart. Please refresh the page and try again.'
                : err;
            obj.dlg.opt.content = obj.wrap_error(msg);
            obj.dlg.setContent();
        })
    }
}



class BTSPromptLightBox extends AddToCartForm {
    constructor(dlg) {
        super('#dialog-content #adt_container form.bts-prompt');
        this.dlg = dlg;
        // this.form_selector = '#dialog-content #adt_container form.bts-prompt';
        this.form_action = '/main-ajax-btsprompt';
    }
    showDialog(oform) {
        var form = oform.cloneNode(true);
        // I have to keep the current lightbox open.
        var obj = this;
        Dialogs.elm('content').firstChild.innerHTML = obj.lb_waiting_prompt;
        let payload = obj.get_payload(form, oform.closest('.itemcard'));
        fetch(obj.form_action, {
            credentials: 'same-origin',
            body: payload,
            headers: {
                'X-Post-ID': nxDatalayer.global.post_id,
                'X-Allow-Wide': nxDatalayer.event.wide.enabled,
                'content-type': 'application/x-www-form-urlencoded'
            },
            method: "POST"
        })
        .then(response => {
            if (!response.ok) {
                throw Error(response.text());
            }
            return response.text();
        })
        .then(responseText => {
            var bts = document.querySelector("#adt_container div.inline_message.inline_message_bts_prompt");
            if(bts) {
                bts.replace(responseText);
                document.getElementById('dialog-content-overlay').remove();
                obj.dlg.setDimensions();
            }
        });
    }
}

const updateOnAdd = function updateOnAdd() {
    nx.addToCart.updateCart();
    nx.addToCart.updateInCartStrip();
    if(typeof nx.cart != 'undefined') { nx.cart.refreshCart();}
}

export {AddToCartForm, updateOnAdd};
